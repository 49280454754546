import $ from "jquery";
import { useEffect } from "react";
import {
  adaCheckerForLeftAndRightArrow,
  adaCheckerForSlideAnnounce,
} from "../checkers";
let windowInnerWidth;

export const getTenantOrgId = () => {
  let TenantOrgIdStr = "";
  let lSTenantOrgId = localStorage.getItem("TenantOrgId");
  if (lSTenantOrgId !== "undefined") {
    let TenantOrgId = JSON.parse(lSTenantOrgId);
    if (TenantOrgId) TenantOrgIdStr = TenantOrgId.toString();
  }
  return TenantOrgIdStr;
};

export const callADA = () => {
  mobileSizeAccordian("0", "-1", "true", null);
  mobileSizeAccordianTabindex();
  openBroadCast();
  openCloseLetter();
  mainMethod();
  setTimeout(() => {
    leftRightGallery();
    onClickEnterOpen("header div.dropdown-toggle");
    leftRightClickToSlide(".carousel-inner .carousel-item");
    clickLearnMoreADA();
    handleReadMoreLetter();
  }, 1000);

  try {
    $(window).ready(function () {
      windowInnerWidth = window.innerWidth;
    });
    $(window).resize(function () {
      // window.location.reload(true)
      if (windowInnerWidth != window.innerWidth) {
        removeListner();

        mobileSizeAccordian("0", "-1", "true", null);
        mobileSizeAccordianTabindex();
        mainMethod();
        setTimeout(() => {
          leftRightGallery();
        }, 1000);
      }
    });
  } catch (error) {}
};

const openBroadCast = () => {
  try {
    document
      ?.querySelector(".broadCastTemplpatesWrapper .learn-more-text")
      ?.addEventListener("keydown", (e) => {
        if (e.which === 13) {
          e.target.click();
        }
      });
  } catch (error) {}
};

const removeListner = () => {
  try {
    document?.querySelectorAll(".learn-more-wrapper")?.forEach((ele) => {
      if (ele.style.display === "block") {
        ele
          .querySelector(".pb-learn-more-text")
          .removeEventListener("keypress", (e) => {
            if (e.which == 13) {
              e.target.click();
            }
          });
      }
    });
  } catch (error) {}
};

const mainMethod = () => {
  openSelectFundOnPressEnter();
  openSelectFundOnPressEnterForOther();
  //Roving index call
  rovingIndexOnMenu(".menu-list li a"); // For Menu Dropdown

  rovingIndexOnSelectFund();
  let ele = document.querySelector("header .dropdown-toggle");
  ele.addEventListener("keypress", (e) => {
    if (e.which === 13) {
      try {
        e.preventDefault();
        //handleMouseClickevent(e);
      } catch (err) {
        console.log(err.message);
      }
    }
  });
  reportUpdateTab();
  // This is setting tabindex 0 and 1 on Download Panel
  setTimeout(() => {
    removeTabindexFromATag();
    setUnSetTabindex("#FOR_DOWNLOAD");
    // clickLearnMoreADA();
    // leftRightClickToSlide(".carousel-inner .carousel-item");
    //leftRightClickToSlide(".photoGalleryCarousel .slick-list .slick-slide");
    //leftRightClickToSlidePhotoGallery();

    Misc();
  }, 2000);
  setTimeout(() => {
    openPdfOnClickDownloadButton();
  }, 3000);
};

export const setUnSetTabindexForGoToFundList = () => {
  if (
    document?.querySelector(".carousel-list-funds").classList.contains("hide")
  ) {
    document?.querySelector(".fund-list-close").removeAttribute("tabindex");
  } else {
    document?.querySelector(".fund-list-close").setAttribute("tabindex", "0");
  }

  if (
    Number(getTenantOrgId()) == 87 ||
    Number(getTenantOrgId()) == 88 ||
    Number(getTenantOrgId()) == 89
  ) {
    rovingIndex(
      ".menu-go-to-fund-wrapper .carousel-list-funds ol.carousel-indicators li"
    ); // For Select Fund
  } else {
    rovingIndex("header .carousel-list-funds ol.carousel-indicators li"); // For Select Fund
  }
};
export const setUnSetTabindexForSelectFundList = (event) => {
  const sectionId = event?.currentTarget?.closest("section").id;

  if (
    document?.querySelector(".carousel-list-funds").classList.contains("hide")
  ) {
    document?.querySelector(".fund-list-close").removeAttribute("tabindex");
  } else {
    document?.querySelector(".fund-list-close").setAttribute("tabindex", "0");
  }
  if (sectionId) {
    rovingIndex(
      `#${sectionId} .fund-footer-wrapper .carousel-list-funds ol.carousel-indicators li`
    );
  } else {
    rovingIndex(
      ".fund-footer-wrapper .carousel-list-funds ol.carousel-indicators li"
    );
  }
};

export const printReportModal = () => {
  try {
    document
      ?.querySelector(".modal-content")
      ?.addEventListener("keydown", (e) => {
        if (e.which == 27) {
          document
            ?.querySelectorAll(".modal-footer button span")
            ?.forEach((e) => {
              if (e.innerHTML == "Cancel") {
                e.click();
              }
            });
        }
      });
  } catch (error) {}
};

const leftRightGallery = () => {
  try {
    document.querySelectorAll("#photoGalleryCarousel")?.forEach((item) => {
      const leftButton = item.querySelector(".slick-prev");
      const rightButton = item.querySelector(".slick-next");

      leftButton.addEventListener("keydown", (e) => {
        if (e.key === "ArrowLeft") {
          leftButton.click();
          slideMovedLeft(e);
        }
        if (e.key === "ArrowRight") {
          rightButton.click();
          slideMovedRight(e);
        }
        if (e.which === 13 && e.target.classList.contains("slick-next")) {
          slideMovedRight(e);
        }

        if (e.which === 13 && e.target.classList.contains("slick-prev")) {
          slideMovedLeft(e);
        }
      });

      rightButton.addEventListener("keydown", (e) => {
        if (e.key === "ArrowRight") {
          rightButton.click();
          slideMovedRight(e);
        }
        if (e.key === "ArrowLeft") {
          leftButton.click();
          slideMovedLeft(e);
        }

        if (e.which === 13 && e.target.classList.contains("slick-next")) {
          slideMovedRight(e);
        }

        if (e.which === 13 && e.target.classList.contains("slick-prev")) {
          slideMovedLeft(e);
        }
      });
    });
  } catch (error) {
    console.log(error);
  }
};

function slideMovedLeft(e) {
  if (adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))) {
    announceContentChange("Slide Moved Left", e?.target);
  }
}

function slideMovedRight(e) {
  if (adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))) {
    announceContentChange("Slide Moved Right", e?.target);
  }
}

const mobileSizeAccordian = (
  from,
  to,
  dataCollapsableTab,
  isSingleCarousel
) => {
  try {
    if (window.innerWidth <= 1000) {
      let carouselArray = isSingleCarousel
        ? isSingleCarousel
        : document?.querySelectorAll(".slideSecClass .carousel");
      carouselArray?.forEach((carousel) => {
        let carouselInner = carousel?.querySelector(".carousel-inner");

        // Mobile accordian is closed
        if (
          !carousel
            ?.querySelector(".slider-collapsable-common")
            ?.classList?.contains(".mobile-accordian")
        ) {
          // Carousel Indicators
          let carouselIndicatorsArray = carousel?.querySelectorAll(
            `.carousel-indicators-common [tabindex="${from}"]`
          );
          carouselIndicatorsArray?.forEach((tabIndex) => {
            tabIndex?.setAttribute("tabindex", to);
            tabIndex?.setAttribute("data-collapsable-tabindex", "true");
          });

          // Carousel Inner
          let carouselInnerIndexArray = carouselInner.querySelectorAll(
            `[tabindex="${from}"]`
          );
          carouselInnerIndexArray?.forEach((tabIndex) => {
            if (!tabIndex?.classList?.contains("slider-collapsing-icon")) {
              tabIndex?.setAttribute("tabindex", to);
              tabIndex?.setAttribute("data-collapsable-tabindex", "true");
            }
          });

          // Carousel Control
          let carouselControlArray =
            carousel?.querySelectorAll("a.carousel-control");
          carouselControlArray?.forEach((carouselControl) => {
            if (carouselControl?.getAttribute("tabindex") !== null) {
              carouselControl?.setAttribute("tabindex", to);
              carouselControl?.setAttribute(
                "data-collapsable-tabindex",
                "true"
              );
            }
          });
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const mobileSizeAccordianTabindex = () => {
  document
    .querySelectorAll(".mobile-view-only .slider-collapsing-icon")
    ?.forEach((item) => {
      item.addEventListener("keydown", (e) => {
        if (e.which === 13) {
          let ele = item
            .closest(".slideSecClass")
            .querySelector(".carousel-inner");
          if (ele.style.display === "none") {
            setTimeout(() => {
              let list = item
                .closest(".slideSecClass")
                .querySelectorAll(".carousel-indicators-common li");
              rovingIndexOnMenu(null, list);
            }, 2000);

            item
              .closest(".slideSecClass")
              .querySelectorAll('[tabindex="-1"]')
              ?.forEach((el) => {
                if (el.getAttribute("data-collapsable-tabindex"))
                  mobileSizeAccordian("-1", "0", "true", [
                    item?.closest(".slideSecClass .carousel"),
                  ]);
              });
          } else {
            // item
            //   .closest(".slideSecClass")
            //   .querySelectorAll('[tabindex="0"]')
            //   ?.forEach((el) => {
            //     if (el.getAttribute("data-collapsable-tabindex"))
            //     mobileSizeAccordian("0", "-1", "true", [item?.closest(".slideSecClass .carousel")]);
            //   });
            mobileSizeAccordian("0", "-1", "true", null);
          }
        }
      });
    });
};

const reportUpdateTab = () => {
  let reportUpdateTabs = document?.querySelectorAll(".report-updates-tab li a");
  reportUpdateTabs?.forEach((tab) => {
    tab?.getAttribute("tabindex") === null &&
      tab?.setAttribute("tabindex", "0");
    tab?.addEventListener("keypress", (e) => {
      if (e?.which === 13) {
        if (
          document?.querySelector(".report-updates-tab li:not(.active)")?.style
            ?.display === "none"
        ) {
          tab?.click();
        } else {
          !tab?.parentNode.classList?.contains("active") && tab?.click();
        }
      }
    });
  });
};

const openPdfOnClickDownloadButton = () => {
  document
    .querySelectorAll("[onclickprivate],[hrefprivate],[href]")
    ?.forEach((ele) => {
      ele.setAttribute("tabindex", 0);
      ele.addEventListener("keypress", (e) => {
        if (e.which === 13) {
          e.target.click();
        }
      });
    });

  document
    ?.querySelectorAll("#FY_REPORT_DOWNLOAD .doc-download-btn")
    ?.forEach((ele) => {
      if (
        !ele.getAttribute("onclickprivate") &&
        !ele.getAttribute("hrefprivate") &&
        !ele.getAttribute("href")
      ) {
        ele.setAttribute("tabindex", -1);
      }
    });
};

const clickLearnMoreADA = () => {
  try {
    document?.querySelectorAll(".learn-more-wrapper")?.forEach((ele) => {
      ele
        .querySelector(".pb-learn-more-text")
        .addEventListener("keypress", (e) => {
          if (e.which == 13) {
            e.target.click();
            if (
              adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
            ) {
              var carouselInner = e?.target?.parentElement
                ?.closest(".contentpanelname")
                ?.querySelector(".carousel-inner");
              var totalElement = carouselInner?.childElementCount;
              var activeElement = carouselInner.querySelector(
                ".carousel-item.active"
              );
              var activeIndex = Array.from(carouselInner.children).indexOf(
                activeElement
              );
              var currentActive = Number(activeIndex) + 1;
              let slideNumber = "";
              if (currentActive !== totalElement) {
                if (Number(getTenantOrgId()) === 85) {
                  slideNumber = `Slide ${Number(currentActive + 1)}`;
                }
                else if(Number(getTenantOrgId()) === 1 || Number(getTenantOrgId()) === 94) {
                  slideNumber = `Slide ${Number(currentActive + 1)} of ${Number(totalElement)}`;
                }
                announceContentChange(
                  slideNumber,
                  e.target.parentElement.closest(".contentpanelname")
                );
              }
            }
          }
        });
    });
  } catch (error) {
    console.log(error);
  }
};

const leftRightClickToSlidePhotoGallery = () => {
  try {
    let carouselList = document?.querySelectorAll("#photoGalleryCarousel");
    carouselList?.forEach(function (items) {
      items?.querySelectorAll("button")?.forEach((item) => {
        if (item.nextElementSibling) {
          item.nextElementSibling.setAttribute("tabindex", "0");
          item.addEventListener("keydown", function (e) {
            if (e.target.closest("#PHOTO_GALLERY")) {
              if (e.which == 13) {
                e.preventDefault();
                item?.nextElementSibling?.click();
                item.setAttribute("tabindex", "-1");
              } else if (e.which == 38) {
                item?.previousElementSibling?.setAttribute("tabindex", "0");
                // item?.previousElementSibling?.click();
                // item.setAttribute("tabindex", "-1");
              } else if (e.which == 40) {
                item.nextElementSibling.setAttribute("tabindex", "0");
                item?.querySelector(".carousel-control-next")?.click();
                //  item.setAttribute("tabindex", "-1");
              }
            }
          });
        }
      });
    });
  } catch (error) {}
};

const checkIfTabindexCorrect = (item) => {
  try {
    if (window.innerWidth > 991) {
      item.querySelectorAll(".carousel-control").forEach((ele) => {
        if (ele.querySelector("span").style.display === "block") {
          ele.setAttribute("tabindex", "0");
        } else {
          ele.setAttribute("tabindex", "-1");
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};
// MAIN-TEMPLATE
const leftRightClickToSlide = (classname) => {
  try {
    let carouselControls = document?.querySelectorAll(".slideSecClass");
    carouselControls?.forEach((item) => {
      let elements = item?.querySelectorAll(classname)?.length;
      let currentIndex = 1;
      if (item.querySelector(".carousel-control-next")) {
        hideTabindexForSTD();
        checkIfTabindexCorrect(item);
        item.addEventListener("keydown", function (e) {
          if (
            e?.which == 13 &&
            elements &&
            e?.target.querySelector("span")?.style?.display === "block"
          ) {
            //item.querySelector(".carousel-control-next").click();
            setTimeout(() => {
              if (e.target.tagName !== "BUTTON") {
                e.target.click();
                if (
                  adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
                ) {
                  var carouselInner =
                    e?.target?.parentElement?.querySelector(".carousel-inner");
                    var totalElement = carouselInner?.childElementCount;
                  var activeElement = carouselInner.querySelector(
                    ".carousel-item.active"
                  );
                  var activeIndex = Array.from(carouselInner.children).indexOf(
                    activeElement
                  );
                  if (e?.target?.classList.contains("carousel-control-prev")) {
                    var currentActive = Number(activeIndex) + 1;
                    let slideNumber = '';
                    if(Number(getTenantOrgId()) === 85) {
                      slideNumber = `Slide ${Number(currentActive - 1)}`;
                    }
                    else if(Number(getTenantOrgId()) === 1) {
                      slideNumber = `Slide ${Number(currentActive - 1)} of ${Number(totalElement)}`;
                    }
                    announceContentChange(slideNumber, e?.target);
                  } else if (
                    e?.target?.classList.contains("carousel-control-next")
                  ) {
                    var currentActive = Number(activeIndex) + 1;
                    let slideNumber = '';
                    if(Number(getTenantOrgId()) === 85) {
                      slideNumber = `Slide ${Number(currentActive + 1)}`;
                    }
                    else if(Number(getTenantOrgId()) === 1 || Number(getTenantOrgId()) === 94) {
                      slideNumber = `Slide ${Number(currentActive + 1)} of ${Number(totalElement)}`;
                    }
                    announceContentChange(slideNumber, e?.target);
                  }
                }
              }
            }, 1000);
            checkIfTabindexCorrect(item);
            setTimeout(() => {
              hideTabindexForSTD();
            }, 1000);
          } else if (
            e.which == 37 &&
            e?.target?.classList.contains("carousel-control")
          ) {
            item
              .querySelector(".carousel-control-prev")
              .setAttribute("tabindex", "0");

            let currentFund = item?.querySelector(".current-fund")?.innerHTML;
            let isSTD = item?.querySelector(".fund_number");
            var carouselInner =
              e?.target?.parentElement?.querySelector(".carousel-inner");
              var totalElement = carouselInner?.childElementCount;
            var activeElement = carouselInner.querySelector(
              ".carousel-item.active"
            );
            var activeIndex = Array.from(carouselInner.children).indexOf(
              activeElement
            );
            var currentActive = Number(activeIndex) + 1;
            let slideNumber = '';
            if(Number(getTenantOrgId()) === 85) {
              slideNumber = `Slide ${Number(currentActive - 1)}`;
            }
            else if(Number(getTenantOrgId()) === 1) {
              slideNumber = `Slide ${Number(currentActive - 1)} of ${Number(totalElement)}`;
            }
            if (isSTD) {
              if (parseInt(currentFund) > 1) {
                item.querySelector(".carousel-control-prev").click();
                if (
                  adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
                ) {
                  announceContentChange(slideNumber, e?.target);
                }
              }
            } else {
              var isFirstSlide = currentActive === 1;
              if (isFirstSlide) {
                item
                  .querySelector(".carousel-control-next")
                  .setAttribute("tabindex", "0");
              } else {
                item.querySelector(".carousel-control-prev").click();
              }
              if (
                adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
              ) {
                if (Number(currentActive - 1) > 0) {
                  announceContentChange(slideNumber, e?.target);
                }
              }
            }

            checkIfTabindexCorrect(item);
            currentIndex--;
            // item.setAttribute("tabindex", "-1");
          } else if (
            e.which == 39 &&
            e?.target?.classList.contains("carousel-control")
          ) {
            item
              .querySelector(".carousel-control-next")
              .setAttribute("tabindex", "0");
            let currentFund = item?.querySelector(".current-fund")?.innerHTML;
            let totalFund = item?.querySelector(".total-fund")?.innerHTML;
            var carouselInner =
              e?.target?.parentElement?.querySelector(".carousel-inner");
              var totalElement = carouselInner?.childElementCount;
            var activeElement = carouselInner.querySelector(
              ".carousel-item.active"
            );
            var activeIndex = Array.from(carouselInner.children).indexOf(
              activeElement
            );
            var currentActive = Number(activeIndex) + 1;
            let slideNumber = '';
            if(Number(getTenantOrgId()) === 85) {
              slideNumber = `Slide ${Number(currentActive + 1)}`;
            }
            else if(Number(getTenantOrgId()) === 1 || Number(getTenantOrgId()) === 94) {
              slideNumber = `Slide ${Number(currentActive + 1)} of ${Number(totalElement)}`;
            }
            let isSTD = item?.querySelector(".fund_number");

            if (isSTD) {
              if (parseInt(currentFund) < parseInt(totalFund)) {
                item.querySelector(".carousel-control-next").click();
                if (
                  adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
                ) {
                  announceContentChange(slideNumber, e?.target);
                }
              } else {
                item
                  .querySelector(".carousel-control-prev")
                  .setAttribute("tabindex", "0");
              }
            } else {
              var totalSlides = carouselInner.children.length;
              var isLastSlide = currentActive === totalSlides;
              if (isLastSlide) {
                item
                  .querySelector(".carousel-control-prev")
                  .setAttribute("tabindex", "0");
              } else {
                item.querySelector(".carousel-control-next").click();
              }
              if (
                adaCheckerForSlideAnnounce?.includes(Number(getTenantOrgId()))
              ) {
                if (Number(currentActive) < totalSlides) {
                  announceContentChange(slideNumber, e?.target);
                }
              }
            }

            checkIfTabindexCorrect(item);
            currentIndex++;
            //  item.setAttribute("tabindex", "-1");
          }
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

function announceContentChange(currentSlide, targetElement) {
  try {
    if (
      targetElement?.parentElement?.classList?.contains("slick-initialized")
    ) {
      var hiddenDiv = document.createElement("div");
      hiddenDiv.className = "hiddenFromScreen";
      hiddenDiv.setAttribute("aria-live", "assertive");
      hiddenDiv.setAttribute("id", "assertiveLiveRegion");

      // Insert the new div element inside the slick-slider slick-initialized div
      targetElement?.parentElement?.appendChild(hiddenDiv);
      inserContent(currentSlide, targetElement);
    } else {
      inserContent(currentSlide, targetElement);
    }
  } catch (error) {
    console.log(error);
  }
}

function inserContent(currentSlide, targetElement) {
  try {
    const assertiveLiveRegion = targetElement?.parentElement?.querySelector(
      "#assertiveLiveRegion"
    );
    assertiveLiveRegion.textContent = currentSlide;
    setTimeout(() => {
      assertiveLiveRegion.textContent = "";
    }, 2000);
  } catch (error) {
    console.log(error);
  }
}

const hideTabindexForSTD = () => {
  try {
    setTimeout(() => {
      if (window.innerWidth > 991) {
        document.querySelectorAll(`.report-cp-panel`).forEach((ele) => {
          if (true) {
            ele.querySelectorAll(".carousel-control").forEach((x) => {
              if (x.querySelector("span").style.display === "block") {
                x.setAttribute("tabindex", "0");
                x.classList.remove("focus-removed");
                // x.classList.add("show");
                if (
                  adaCheckerForLeftAndRightArrow?.includes(
                    Number(getTenantOrgId())
                  )
                ) {
                  // x.style.setProperty("visibility", "visible", "important");
                  // x.classList.remove("hide");
                  x.removeAttribute("aria-hidden");
                  // x.setAttribute("aria-hidden", "false");
                  x.setAttribute("tabindex", 0);
                }
              } else {
                x.setAttribute("tabindex", "-1");
                if (
                  adaCheckerForLeftAndRightArrow?.includes(
                    Number(getTenantOrgId())
                  )
                ) {
                  // x.style.setProperty("visibility", "hidden", "important");
                  x.classList.add("focus-removed");
                  x.setAttribute("aria-hidden", "true");
                  x.setAttribute("tabindex", "-1");
                }
              }
            });
          }
        });
      }
    }, 1000);
  } catch (error) {}
};

export const adaLibMenu = () => {
  try {
    let ele = document.querySelector("#IdHeader ul")?.getAttribute("role");
    if (ele && ele === "menu") {
      let element = document.querySelector("#IdHeader ul");
      if (element.classList.contains("dropdown-menu")) {
        element.classList.remove("dropdown-menu");
        element.classList.add("dropdown-menu-toggle");
      }
    }
  } catch (error) {}
};

export const rovingIndexOnSelectFund = () => {
  document?.querySelectorAll(".fund-footer-wrapper")?.forEach((ele) => {
    let list = ele?.querySelectorAll("ol.carousel-indicators li");
    closeButton(ele?.querySelector(".fund-list-close"));
    rovingIndex(null, list);
  });
};
const closeButton = (ele) => {
  ele.addEventListener("keypress", (e) => {
    if (e.which == 13) {
      e.target.click();
    }
  });
};

const openSelectFundOnPressEnter = () => {
  const divsWithClassOrClick = document.querySelectorAll(
    "div.fund-list-button[onclick]"
  );

  divsWithClassOrClick?.forEach((ele) => {
    ele.addEventListener("keydown", (e) => {
      let carouselListFunds = ele
        .closest(".fund-wrapper")
        ?.querySelector(".carousel-list-funds");
      let elindicators = carouselListFunds?.querySelector(
        ".carousel-indicators"
      );
      setUnSetTabindexForGoToFundList();
      if (e.which == 27) {
        setUnSetTabindexForGoToFundList();
        // setTimeout(() => {
        //   e.target
        //     .closest(".fund-footer-wrapper")
        //     ?.querySelector(".fund-list-close button")
        //     ?.click();

        //   e.target
        //     .closest(".fund-wrapper")
        //     ?.querySelector(".fund-list-close button")
        //     ?.click();
        // }, 100);
        carouselListFunds?.classList?.add("hide");
        if (elindicators) elindicators.style.display = "none";
      }
      if (e.which == 13) {
        if (carouselListFunds?.classList?.contains("hide")) {
          carouselListFunds.classList.remove("hide");
          if (elindicators) elindicators.style.display = "block";
        } else {
          carouselListFunds?.classList?.add("hide");
          if (elindicators) elindicators.style.display = "none";
        }
      }
    });
  });

  const fundListCloseList = document.querySelectorAll(".fund-list-close");
  fundListCloseList?.forEach((fundListClose) => {
    fundListClose.addEventListener("keydown", (e) => {
      if (e.which === 13) {
        let carouselListFunds = fundListClose.closest(".carousel-list-funds");
        if (carouselListFunds?.classList?.contains("hide")) {
          carouselListFunds.classList.remove("hide");
        } else {
          carouselListFunds?.classList?.add("hide");
        }
      }
    });
  });
};

const openSelectFundOnPressEnterForOther = () => {
  const divsWithClassOrClick = document.querySelectorAll(
    "span.btn_select_fund[onclick]"
  );
  divsWithClassOrClick?.forEach((ele) => {
    ele.addEventListener("keydown", (e) => {
      let carouselListFunds = ele
        .closest(".fund-footer-wrapper")
        ?.querySelector(".carousel-list-funds");
      let elindicators = carouselListFunds?.querySelector(
        ".carousel-indicators"
      );
      setUnSetTabindexForSelectFundList(e);
      if (e.which == 27) {
        setUnSetTabindexForSelectFundList(e);
        // setTimeout(() => {
        //   e.target
        //   .closest(".fund-footer-wrapper")
        //   ?.querySelector(".fund-list-close button")
        //   ?.click();

        //   e.target
        //   .closest(".fund-wrapper")
        //   ?.querySelector(".fund-list-close button")
        //   ?.click();
        // }, 100);
        carouselListFunds?.classList?.add("hide");
        if (elindicators) elindicators.style.display = "none";
      } else if (e.which == 13) {
        if (
          adaCheckerForLeftAndRightArrow?.includes(Number(getTenantOrgId()))
        ) {
          carouselListFunds
            ?.querySelectorAll(".carousel-indicators li")
            ?.forEach((indicator) => {
              indicator.removeAttribute("aria-hidden");
              // indicator.setAttribute("aria-hidden", "false");
            });
        }

        if (carouselListFunds?.classList?.contains("hide")) {
          carouselListFunds.classList.remove("hide");
          if (elindicators) elindicators.style.display = "block";
        } else {
          carouselListFunds?.classList?.add("hide");
          if (elindicators) elindicators.style.display = "none";
        }
      }
      //rovingIndexOnMenu(".carousel-list-funds ol.carousel-indicators li");
    });
  });
};

export const setUnSetTabindex = (parent) => {
  if (parent === "#FOR_DOWNLOAD" || parent === "#FOR-DOWNLOAD") {
    document?.querySelectorAll("#FOR_DOWNLOAD")?.forEach((item) => {
      if (
        item?.querySelector("a.assetseditor")?.getAttribute("onclickprivate")
      ) {
        item?.querySelector("a.assetseditor")?.setAttribute("tabindex", 0);
      } else {
        item?.querySelector("a.assetseditor")?.setAttribute("tabindex", -1);
      }
    });
    document?.querySelectorAll("#FOR-DOWNLOAD")?.forEach((item) => {
      if (
        item?.querySelector("a.assetseditor")?.getAttribute("onclickprivate")
      ) {
        item?.querySelector("a.assetseditor")?.setAttribute("tabindex", 0);
      } else {
        item?.querySelector("a.assetseditor")?.setAttribute("tabindex", -1);
      }
    });
  }
};

export const onClickEnterOpen = (classname) => {
  try {
    document.querySelectorAll(classname)?.forEach((item) => {
      item?.addEventListener("keydown", (e) => {
        let menuList = document?.querySelector("ul.menu-list")?.style?.display;
        if (e.which == 13) {
          e.target.click();
        } else if (e.which == 27 && menuList != "none") {
          e.target.click();
        }
      });
    });
  } catch (error) {}
};

export const rovingIndexOnMenu = (classname, list, isMenu) => {
  try {
    var btns = list || document.querySelectorAll(classname);
    btns.forEach((btn, index) => {
      // Set the first button tabindex to 0
      // and set every other button tabindex to -1
      if (index === 0) {
        btn.setAttribute("tabindex", 0);
      } else {
        btn.setAttribute("tabindex", -1);
      }

      // Add an event listener for keydown event
      btn.addEventListener("keydown", (e) => {
        if (
          e.key === "ArrowDown" ||
          e.key === "ArrowUp" ||
          e.key === "Enter" ||
          e.key === "Tab" ||
          e.key === "Escape"
        ) {
          // Prevent the default behavior
          e.preventDefault();

          // Set current button tabindex to -1
          btn.setAttribute("tabindex", -1);
          if (e.keyCode === 13) {
            if (e.target.querySelector("a")) {
              e.target.querySelector("a").click();
            } else if (e.target) e.target.click();
          }
          if (e.keyCode === 27) {
            let menuList =
              document?.querySelector("ul.menu-list")?.style?.display;
            if (e.which == 27 && menuList != "none") {
              document?.querySelector("header div.dropdown-toggle")?.click();
            }
          }

          if (e.key === "ArrowDown" || (!e.shiftKey && e.key === "Tab")) {
            // Move focus to the next button
            let nextEl =
              btn.nextElementSibling ||
              (btn?.parentElement?.nextElementSibling?.childNodes &&
                btn?.parentElement?.nextElementSibling?.childNodes[0]);
            if (nextEl) {
              nextEl.setAttribute("tabindex", 0);
              nextEl.focus();
            } else {
              // If there is no next button, wrap to the first button
              let firstEl = btns[0];
              firstEl.setAttribute("tabindex", 0);
              firstEl.focus();
            }
          } else if (e.key === "ArrowUp") {
            // Move focus to the previous button
            let prevEl =
              btn.previousElementSibling ||
              (btn?.parentElement?.previousElementSibling?.childNodes &&
                btn?.parentElement?.previousElementSibling?.childNodes[0]);
            if (prevEl) {
              prevEl.setAttribute("tabindex", 0);
              prevEl.focus();
            } else {
              // If there is no previous button, wrap to the last button
              let lastEl = btns[btns.length - 1];
              lastEl.setAttribute("tabindex", 0);
              lastEl.focus();
            }
          } else {
            if (
              btn.innerHTML === "Home" ||
              btn.innerHTML === "HOME" ||
              ((Number(getTenantOrgId()) == 87 ||
                Number(getTenantOrgId()) == 88 ||
                Number(getTenantOrgId()) == 89 ||
                Number(getTenantOrgId()) == 92 ||
                Number(getTenantOrgId()) == 36 ||
                Number(getTenantOrgId()) == 90 ||
                Number(getTenantOrgId()) == 93 ||
                Number(getTenantOrgId()) == 94) &&
                (btn.innerHTML.includes("Report") ||
                  btn.innerHTML.includes("Your Site")) &&
                !btn.parentElement.previousElementSibling)
            ) {
              let prevEl = btn.closest(".menu-list").previousElementSibling;
              if (
                Number(getTenantOrgId()) == 86 ||
                Number(getTenantOrgId()) == 65 ||
                Number(getTenantOrgId()) == 87 ||
                Number(getTenantOrgId()) == 88 ||
                Number(getTenantOrgId()) == 89 ||
                Number(getTenantOrgId()) == 92 ||
                Number(getTenantOrgId()) == 36 ||
                Number(getTenantOrgId()) == 90 ||
                Number(getTenantOrgId()) == 93 ||
                Number(getTenantOrgId()) == 94
              ) {
                if (
                  Number(getTenantOrgId()) == 90 ||
                  Number(getTenantOrgId()) == 93 ||
                  (Number(getTenantOrgId()) == 36 &&
                    document
                      .getElementsByTagName("body")[0]
                      ?.classList.contains("REBRAND_2024_BODY"))
                ) {
                  prevEl = btn.closest(".menu-list").previousElementSibling;
                } else {
                  prevEl = btn
                    .closest(".menu-list")
                    .previousElementSibling?.querySelector(".dropdown-toggle");
                }
              }
              if (prevEl) {
                prevEl.setAttribute("tabindex", 0);
                btn.setAttribute("tabindex", 0);
                prevEl.focus();
              } else {
                // If there is no previous button, wrap to the last button
                let lastEl = btns[btns.length - 1];
                lastEl.setAttribute("tabindex", 0);
                lastEl.focus();
              }
            } else {
              let prevEl =
                btn.previousElementSibling ||
                (btn?.parentElement?.previousElementSibling?.childNodes &&
                  btn?.parentElement?.previousElementSibling?.childNodes[0]);
              if (prevEl) {
                prevEl.setAttribute("tabindex", 0);
                btn.setAttribute("tabindex", 0);
                prevEl.focus();
              } else {
                // If there is no previous button, wrap to the last button
                let lastEl = btns[btns.length - 1];
                lastEl.setAttribute("tabindex", 0);
                lastEl.focus();
              }
            }
          }
        }
      });
    });
  } catch (error) {}
};

export const rovingIndex = (classname, list) => {
  try {
    var btns = list || document.querySelectorAll(classname);
    btns.forEach((btn, index) => {
      // Set the first button tabindex to 0
      // and set every other button tabindex to -1
      if (
        index === 0 &&
        !btn.closest(".carousel-list-funds").classList.contains("hide")
      ) {
        btn.setAttribute("tabindex", 0);
      } else {
        btn.setAttribute("tabindex", -1);
      }

      // Add an event listener for keydown event
      btn.addEventListener("keydown", (e) => {
        if (
          e.key === "ArrowDown" ||
          e.key === "ArrowUp" ||
          e.key === "Enter" ||
          e.key === "Escape" ||
          e.key === "Tab"
        ) {
          // Prevent the default behavior
          e.preventDefault();

          // Set current button tabindex to -1
          btn.setAttribute("tabindex", -1);
          if (e.keyCode === 13) {
            if (e.target.querySelector("a")) {
              e.target.querySelector("a").click();
            } else if (e.target) e.target.click();
          }

          if (e.keyCode === 27) {
            // e.preventDefault();
            // e.stopPropagation();

            // let ele = e.target.closest("[onclick='closeFundList']");
            let ele = e.target
              .closest(".carousel-list-funds")
              ?.querySelector(".fund-list-close");
            if (ele) {
              let onclickValue = ele.getAttribute("onclick");
              if (
                onclickValue.includes("closeFundList") &&
                !e.target
                  .closest(".carousel-list-funds")
                  ?.classList?.contains("hide")
              ) {
                ele?.click();
              }
            }
            var btns = list || document.querySelectorAll(classname);
            btns.forEach((btn, index) => {
              // Set the first button tabindex to 0
              // and set every other button tabindex to -1
              if (index === 0) {
                btn.setAttribute("tabindex", 0);
              } else {
                btn.setAttribute("tabindex", -1);
              }
            });
          }

          // Get all elements with the attribute tabindex
          var elements = document.querySelectorAll(
            ".menu-go-to-fund-wrapper .list-fund-options .carousel-indicators [tabindex]"
          );

          // Find the index of the element with tabindex="0"
          var index = Array.from(elements).findIndex(function (element) {
            return element.getAttribute("tabindex") === "0";
          });

          if (e.key === "ArrowDown" || (!e.shiftKey && e.key === "Tab")) {
            var btns = list || document.querySelectorAll(classname);
            // Move focus to the next button
            let nextEl = btn.nextElementSibling;
            if (nextEl) {
              nextEl.setAttribute("tabindex", 0);
              nextEl.focus();
            } else {
              // If there is no next button, wrap to the first button
              let firstEl = btns[0];
              firstEl.setAttribute("tabindex", 0);
              firstEl.focus();
            }
          } else if (e.key === "ArrowUp") {
            var btns = list || document.querySelectorAll(classname);
            // Move focus to the previous button
            let prevEl = btn.previousElementSibling;
            if (prevEl) {
              prevEl.setAttribute("tabindex", 0);
              prevEl.focus();
            } else {
              // If there is no previous button, wrap to the last button
              let lastEl = btns[btns.length - 1];
              lastEl.setAttribute("tabindex", 0);
              lastEl.focus();
            }
          } else if (e.shiftKey && e.key === "Tab") {
            if (index === -1 || index === 0) {
              setUnSetTabindexForGoToFundList();
              var btns = list || document.querySelectorAll(classname);
              // Move focus to the previous button
              btns.forEach((btn) => {
                let prevEl = btn.parentElement
                  .closest(".carousel-list-funds")
                  .querySelector(".fund-list-close");
                if (
                  prevEl &&
                  !btn.parentElement.closest(".fund-footer-wrapper")
                ) {
                  prevEl.setAttribute("tabindex", 0);
                  prevEl.focus();
                } else {
                  prevEl.querySelector("button").setAttribute("tabindex", 0);
                  prevEl.querySelector("button").focus();
                }
              });
            }
          }
        }
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeTabindexFromATag = () => {
  try {
    document?.querySelectorAll("#photoGalleryCarousel a")?.forEach((item) => {
      if (item?.getAttribute("tabindex") == 0) {
        item.setAttribute("tabindex", -1);
      }
    });
  } catch (error) {}
};

export const jumpToContent = (bottomRef) => {
  try {
    const skipToContentLink = document.querySelector(".skip-to-content-link");
    if (skipToContentLink) {
      skipToContentLink.style.display = "block";

      const handleButtonClick = (e) => {
        e.preventDefault();
        const greetingsPanel = document.querySelector("#greetingsPanel");
        if (greetingsPanel) {
          greetingsPanel.scrollIntoView({ behavior: "smooth" });
          greetingsPanel.querySelector(".GS_1").focus();
        }
        skipToContentLink.blur();
      };

      skipToContentLink.addEventListener("click", handleButtonClick);
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleDownloadForADA = () => {
  try {
    setTimeout(() => {
      document
        ?.querySelectorAll(
          "a[hrefprivate], a[onclickprivate],div[onclickprivate],a[href]"
        )
        ?.forEach((ele) => {
          if (
            !ele.classList.contains("visit-site-link") &&
            (ele.classList.contains("assetseditor") ||
              ele.classList.contains("btn-download") ||
              ele.classList.contains("download_link"))
          ) {
            if (
              ele.getAttribute("onclickprivate") ||
              ele.getAttribute("hrefprivate") ||
              ele.getAttribute("href")
            ) {
              ele.setAttribute("tabindex", "0");
              ele.setAttribute("role", "button");
            } else {
              ele.setAttribute("tabindex", "-1");
              ele.removeAttribute("role");
            }
          }
        });
      hideTabindexForSTD();
    }, 3000);
  } catch (error) {}
};

export const handleReadMoreLetter = () => {
  try {
    setTimeout(() => {
      document?.querySelectorAll(".latter-read-more")?.forEach((ele) => {
        if (ele?.classList.contains("latter-read-more")) {
          ele.setAttribute("tabindex", "0");
          ele.setAttribute("role", "button");
        }
      });
    }, 3000);
  } catch (error) {
    console.log(error);
  }
};

const openCloseLetter = () => {
  try {
    if (document?.querySelectorAll(".latter-read-more").length > 0) {
      document?.querySelectorAll(".latter-read-more")?.forEach((ele) => {
        ele?.addEventListener("keypress", (e) => {
          if (e.which == 13) {
            e.target.click();
          }
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const Misc = () => {
  document
    ?.querySelector("header .fund-list-button")
    ?.setAttribute("aria-label", "Go to fund");

  if (
    Number(getTenantOrgId()) == 85 ||
    Number(getTenantOrgId()) == 42 ||
    Number(getTenantOrgId()) == 93
  ) {
    document
      ?.querySelector("header .fund-list-button")
      ?.setAttribute("aria-label", "Select Fund");
  }

  // const rightArrows = document?.querySelectorAll(".right-arrow");
  // rightArrows?.forEach((arrow) => {
  //   arrow.setAttribute("aria-label", "Right arrow");
  // });
  // const leftArrows = document?.querySelectorAll(".left-arrow");
  // leftArrows?.forEach((arrow) => {
  //   arrow.setAttribute("aria-label", "Left arrow");
  // });

  document
    .querySelectorAll(".fund-wrapper .carousel-indicators-common.report li")
    .forEach((ele) => {
      ele.addEventListener("keypress", (e) => {
        if (e.which === 13) {
          e.target.click();

          adaOnSelectFund();

          //callbackForFundWrapper(e.target);
        }
      });
    });

  if (!Number(getTenantOrgId()) == 42) {
    document
      ?.querySelector(".prospect-name-wrapper")
      ?.setAttribute("tabindex", "0");
  }
  document
    ?.querySelector("#outputPrintBridgeSelectionReport")
    ?.setAttribute("tabindex", "0");

  document
    ?.querySelector("#printBridgeSelectionReportModal h1.modal-title")
    ?.setAttribute("id", "myModalLabel");
  document
    ?.querySelector("#fkTemplateId")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='fkTemplateId' style='display:none'>fkTemplateId</label>"
    );
  document
    ?.querySelector("#orgId")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='orgId' style='display:none'>orgId</label>"
    );
  document
    ?.querySelector("#recordId")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='recordId' style='display:none'>recordId</label>"
    );
  document
    ?.querySelector("#copyRightYear")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='copyRightYear' style='display:none'>copyRightYear</label>"
    );
  document
    ?.querySelector("#reportIdForCoverImage")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='reportIdForCoverImage' style='display:none'>reportIdForCoverImage</label>"
    );
  document
    ?.querySelector("#maxcpallowed")
    ?.insertAdjacentHTML(
      "beforebegin",
      "<label for='maxcpallowed' style='display:none'>maxcpallowed</label>"
    );

  if (document?.querySelector("#fundPanelSequenceDetail")) {
    document
      .querySelector("#fundPanelSequenceDetail")
      .insertAdjacentHTML(
        "beforebegin",
        "<label for='fundPanelSequenceDetail' style='display:none'>fundPanelSequenceDetail</label>"
      );
  }
  if (document?.querySelector("#siteIdForCoverImage")) {
    document
      .querySelector("#siteIdForCoverImage")
      .insertAdjacentHTML(
        "beforebegin",
        "<label for='siteIdForCoverImage' style='display:none'>siteIdForCoverImage</label>"
      );
  }
};
